/* eslint-disable import/no-unresolved */
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import ButtonSecondary from 'components/elements/ButtonSecondary'

// Shared
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

const Box = styled.div`
  border-radius: 19px;

`
const CustomImage = styled(Image)`
  min-width: 350px;
  max-height: 258px;

  @media (max-width: 991px){
    width: 100%;
    object-fit: cover;
  }
`

const ContentBox = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.face.light};
  filter: drop-shadow(0px 0px 4px rgba(0,0,0,.50));
 
`

const Title = styled(ParseContent)`
  font-family: ${props => props.theme.font.family.bold};
`

const Blog = ({ posts }) => {
  return (
    <div className="row">
      {posts.map(({ node }) => (
        <div key={node.wordpress_id} className="col-lg-4 col-md-6 mx-3 mx-lg-0 d-flex flex-column">
          <Post fields={node} />
        </div>
      ))}
    </div>
  )
}

const Post = ({ fields }) => (
  <Box className="col-lg-12 pb-5 px-0">
    
    <CustomImage src={fields.acf.overview.image} />
    <ContentBox className="p-3 mx-4 mt-n5">
      <Title content={fields.acf.overview.title} />
      <ButtonSecondary to={fields.path} className="text-right">Lees meer</ButtonSecondary>
    </ContentBox>
  </Box>
)

export default Blog
