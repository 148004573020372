/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'

// Blog
import Blog from 'components/related/Blog'
import Blogik, { BlogConsumer, BlogButton } from 'components/shared/Blogik'
import BlogFilters from 'components/elements/BlogFilters'


const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <div className="container py-5">
        <Blogik
          settings={{
            limit: null
          }}
        >
          
          <BlogConsumer>
            {({ hasPosts, posts, showMoreButton, showLessButton }) => {
              return (
                
                <div>
                  <div className="my-4 d-md-flex align-items-center">
                    <div className="d-flex mt-md-0 mt-3">
                      <BlogFilters />
                    </div>
                  </div>

                  {hasPosts && (
                    <Blog posts={posts} />
                  )}

                  {!hasPosts && (
                    <ParseContent content="Geen resultaten" />
                  )}

                  {/* <div className="text-center mt-5">
                    {showMoreButton && (
                      <BlogButton>
                        Meer tonen
                      </BlogButton>
                    )}
                    {showLessButton && (
                      <BlogButton increment={false}>
                        Minder tonen
                      </BlogButton>
                    )}
                  </div> */}

                </div>
              )
            }}
          </BlogConsumer>
        </Blogik>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
