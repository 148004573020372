/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

import ButtonArrow from 'img/button-arrow.inline.svg'

const StyledButton = styled.div`
  
  & > a, & > button {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.mm};
    color: ${props => props.theme.color.text.secondary};
    letter-spacing: 1.43px;
    width: 100%;
    height: 100%;
    display: block;
  }
`

const ButtonSecondary = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
          <ButtonArrow className="mr-2" />
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonSecondary